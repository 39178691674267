var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-list"},[(_vm.completionsQuery)?_c('section',{staticClass:"list-search mb-3 pb-3"},[_c('label',{attrs:{"for":"auto-complete"}},[_vm._v(" "+_vm._s(_vm.$t("components.baseList.autoComplete"))+" ")]),_c('BaseAutoComplete',{attrs:{"id":"auto-complete","graph-query":_vm.completionsQuery,"placeholder":_vm.$t('components.baseList.autoCompletePlaceHolder'),"map":_vm.nameMap},on:{"select":_vm.autoCompleteSelected},scopedSlots:_vm._u([{key:"noResult",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("components.baseList.noResult")))])]},proxy:true},{key:"option",fn:function({ option: { name } }){return [_c('span',[_vm._v(_vm._s(name))])]}},{key:"singleLabel",fn:function({ option: { name } }){return [_c('span',[_vm._v(_vm._s(name))])]}}],null,false,3229311214)})],1):_vm._e(),_c('div',{staticClass:"element-list-list"},[(_vm.label)?_c('p',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"pb-3"},[_vm._t("list-extras")],2),(_vm.myNetworkStatus < 7)?_c('b-spinner',{staticClass:"m-2",attrs:{"label":_vm.$t('general.loadingStatus'),"variant":"info"}}):_c('b-list-group',{staticClass:"list-group-flush",attrs:{"tag":"ul"}},_vm._l((_vm.listSelection),function(element){return _c('b-list-group-item',{key:element.id,attrs:{"tag":"li"}},[_vm._t("element",function(){return [(_vm.route)?_c('router-link',{class:{
              'is-draft': _vm.isDraft(element.id)
            },attrs:{"to":{
              name: _vm.route,
              params: { id: element.id }
            }}},[_vm._v(" "+_vm._s(element.name || _vm.unknownName)),_vm._t("extra",null,{"element":element})],2):_vm._e(),(!_vm.route)?_c('a',{class:{
              'is-draft': _vm.isDraft(element.id)
            },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.listSelected(element)}}},[_vm._v(" "+_vm._s(element.name || _vm.unknownName)),_vm._t("extra",null,{"element":element})],2):_vm._e()]},{"route":_vm.route,"element":element,"isDraft":_vm.isDraft}),_c('div',_vm._l((_vm.missingData(element)),function(field){return _c('b-badge',{key:field,staticClass:"ml-2 p-1",attrs:{"variant":"warning"}},[_vm._v(_vm._s(_vm.$t(_vm.localeSection + "." + field)))])}),1)],2)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }