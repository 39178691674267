<!--
  A list of elements with an optional auto complete component.

  Props:
    list-query: a mandatory GraphQL query that returns a list called 'list'
    completions-query: an optional GraphQL that returns a list called 'completions'
    filter: an optional function that takes an element and returns true or false
    sort: an optional function that takes two elements and returns true or false
    label: an optional label that is displayed before the list
    name-map: function to map elements to the form { name: "something", ... }
    route: an optional route name. When selecting an element, this route will be loaded, with an id param
    locale-section: where to look for language labels specific to this particular list
    unknownName: an optional string to use when item has no name

  Slots:
    element: mandatory, shown for each element in the list
    option: optional, shown for each element in the autocomplete
    singleLabel: optional: shown when one element is selected in the auto complete

  Events:
    listSelected: emitted when an element is clicked in the list
    select: emitted when an element is selected in the auto complete
-->

<template>
  <div class="element-list">
    <section v-if="completionsQuery" class="list-search mb-3 pb-3">
      <label for="auto-complete">
        {{ $t("components.baseList.autoComplete") }}
      </label>
      <BaseAutoComplete
        id="auto-complete"
        :graph-query="completionsQuery"
        :placeholder="$t('components.baseList.autoCompletePlaceHolder')"
        :map="nameMap"
        @select="autoCompleteSelected"
      >
        <!-- What to show when we have no results -->
        <template v-slot:noResult>
          <span>{{ $t("components.baseList.noResult") }}</span>
        </template>
        <!-- Template for each element in the auto complete -->
        <template v-slot:option="{ option: { name } }">
          <span>{{ name }}</span>
        </template>

        <!-- Template for a selected element in the auto complete -->
        <template v-slot:singleLabel="{ option: { name } }">
          <span>{{ name }}</span>
        </template>
      </BaseAutoComplete>
    </section>

    <div class="element-list-list">
      <p v-if="label">{{ label }}</p>
      <div class="pb-3">
        <slot name="list-extras" />
      </div>
      <b-spinner
        v-if="myNetworkStatus < 7"
        :label="$t('general.loadingStatus')"
        class="m-2"
        variant="info"
      />
      <b-list-group v-else tag="ul" class="list-group-flush">
        <b-list-group-item
          v-for="element of listSelection"
          :key="element.id"
          tag="li"
        >
          <slot
            :route="route"
            :element="element"
            :isDraft="isDraft"
            name="element"
          >
            <!-- Default element template -->
            <router-link
              v-if="route"
              :class="{
                'is-draft': isDraft(element.id)
              }"
              :to="{
                name: route,
                params: { id: element.id }
              }"
            >
              {{ element.name || unknownName
              }}<slot :element="element" name="extra"></slot>
            </router-link>
            <a
              v-if="!route"
              :class="{
                'is-draft': isDraft(element.id)
              }"
              href="#"
              @click.prevent="listSelected(element)"
            >
              {{ element.name || unknownName
              }}<slot :element="element" name="extra"></slot>
            </a>
          </slot>
          <div>
            <b-badge
              v-for="field of missingData(element)"
              :key="field"
              class="ml-2 p-1"
              variant="warning"
              >{{ $t(localeSection + "." + field) }}</b-badge
            >
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "BaseList",
  components: {
    BaseAutoComplete: () => import("@/components/BaseAutoComplete")
  },
  props: {
    // Custom function that
    filter: {
      type: Function,
      default: () => true
    },
    sort: {
      type: Function,
      default: (e1, e2) => {
        const name1 = e1.name instanceof String ? e1.name : "" + e1.name;
        const name2 = e2.name instanceof String ? e2.name : "" + e2.name;
        return name1.localeCompare(name2, "sv", { numeric: true });
      }
    },
    /* 
      A custom mapping function to map elements to the form 
      { name: "something", ... } if the elements are complex,
      like for example the signum objects which have signum1 and signum2
    */
    nameMap: {
      type: Function,
      default: e => e
    },
    listQuery: {
      type: Object,
      required: true
    },
    completionsQuery: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    localeSection: {
      type: String,
      default: "default"
    },
    route: {
      type: String,
      default: null
    },
    /*
      String to use when element has no name
    */
    unknownName: {
      type: String,
      default: "?"
    }
  },
  data() {
    return {
      list: [],
      draftsByOriginalId: {},
      draftsByDraftId: {},
      myNetworkStatus: 1
    };
  },
  computed: {
    listSelection: function() {
      // map elements to aquire names and
      // filter out unwanted elements
      let selection = this.list
        ? this.list
            // Exclude drafts unless unpublished
            .filter(
              e =>
                !this.draftsByDraftId[e.id] ||
                this.draftsByDraftId[e.id].draftId ==
                  this.draftsByDraftId[e.id].originalId
            )
            .map(this.nameMap)
            .filter(this.filter)
        : [];
      // sort if there is a sort function
      return this.sort ? selection.sort(this.sort) : selection;
    },
    loading() {
      return this.$apollo.queries.list.loading;
    }
  },
  methods: {
    goToRoute(id) {
      if (this.route) {
        this.$router.push({
          name: this.route,
          params: { id }
        });
        return true;
      }
      return false;
    },
    autoCompleteSelected(element) {
      if (!this.goToRoute(element.id)) {
        this.$emit("select", element);
      }
    },
    listSelected(element) {
      if (!this.goToRoute(element.id)) {
        this.$emit("listSelected", element);
      }
    },
    isDraft(originalId) {
      return (
        this.draftsByOriginalId[originalId] &&
        this.draftsByOriginalId[originalId].draftId
      );
    },
    /**
     * Checks if the element has one or more fields with missing values.
     */
    missingData(element) {
      let ownKeys = Object.keys(element).filter(
        elementKey =>
          element.hasOwnProperty(elementKey) && !elementKey.startsWith("__")
      );

      return ownKeys
        .map(elementKey =>
          this.missingDataHelp(elementKey, element[elementKey])
        )
        .filter(e => e != null);
    },
    missingDataOld(element) {
      let data = this.missingDataHelp(null, element)
        .flat(999)
        .filter(e => e != null);

      return data;
    },

    missingDataHelp(key, element) {
      if (
        key !== "name" &&
        key !== "id" &&
        (element === null ||
          (Array.isArray(element) && element.length === 0) ||
          element === undefined ||
          element === "")
      ) {
        return key;
      } else {
        return null;
      }
    },
    missingDataHelpOld(key, element) {
      //console.log("key", key);
      //console.log("element", element);
      if (Array.isArray(element)) {
        return element.length === 0
          ? key
          : element.map(element => this.missingDataHelp(key, element));
      }
      if (element !== null && typeof element === "object") {
        let ownKeys = Object.keys(element).filter(
          elementKey =>
            element.hasOwnProperty(elementKey) && !elementKey.startsWith("__")
        );

        return ownKeys.map(elementKey =>
          this.missingDataHelp(elementKey, element[elementKey])
        );
      }

      if (
        key !== "name" &&
        key !== "id" &&
        (element === null || element === undefined || element === "")
      ) {
        //console.log("Missing: ", key, ":", element);
        return key;
      } else {
        return null;
      }
    }
  },
  apollo: {
    list() {
      // If listQuery has a query property, it's a query Object,
      // otherwise it's a gql-string and we'll wrap it in an object
      let query = this.listQuery.query
        ? this.listQuery
        : {
            query: this.listQuery
          };
      // Add options to the query
      let queryWithOptions = {
        ...query,
        // Set pollInterval to between 10 and 11 seconds to spread the queries somewhat in time.
        // FIXME: almost all lists are fetched at exactly the same time anyway - why? Does Vue Apollo
        // only use the last value? But why is one list fetched at another interval? What is happening?
        pollInterval: Math.floor(10000 + Math.random() * 1000)
      };
      // Try a workaround for vue-apollo library not handling loading state for no-cache
      let queryWithNetworkStatus = {
        ...queryWithOptions,
        result(res) {
          this.myNetworkStatus = res.networkStatus;
        }
      };

      return queryWithNetworkStatus;
    },
    drafts() {
      return {
        query: gql`
          {
            drafts {
              originalId
              draftId
            }
          }
        `,
        result(result) {
          this.draftsByOriginalId = result.data.drafts.reduce(function(
            map,
            draft
          ) {
            map[draft.originalId] = draft;
            return map;
          },
          {});
          this.draftsByDraftId = result.data.drafts.reduce(function(
            map,
            draft
          ) {
            map[draft.draftId] = draft;
            return map;
          },
          {});
        }
      };
    }
  }
};
</script>
